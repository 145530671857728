import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-3436edea"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "main_div" };
var _hoisted_2 = { class: "" };
var _hoisted_3 = ["src"];
var _hoisted_4 = {
    class: "fs_32 fw_700 lh_40",
    style: { "color": "#182135" }
};
var _hoisted_5 = {
    class: "fw_600 fs_11 lh_16 text-center",
    style: { "color": "#ff593e" }
};
var _hoisted_6 = {
    class: "flex_7",
    style: { "color": "#626264" }
};
var _hoisted_7 = {
    class: "d-flex align-self-center fw_400 fs_16 lh_24",
    style: { "color": "#626264" }
};
var _hoisted_8 = {
    class: "fw_700 fs_16 lh_40 mt-3",
    style: { "color": "#182135" }
};
var _hoisted_9 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    var _component_base_flex = _resolveComponent("base-flex");
    var _component_base_icon = _resolveComponent("base-icon");
    var _component_BaseIcon = _resolveComponent("BaseIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
                src: (_a = _ctx.mainData) === null || _a === void 0 ? void 0 : _a.image_path,
                class: "object_fit_image"
            }, null, 8, _hoisted_3)
        ]),
        _createVNode(_component_base_flex, {
            class: "main_product_title justify-content-between",
            style: { "margin-top": "40px" }
        }, {
            default: _withCtx(function () {
                var _a;
                return [
                    _createElementVNode("span", _hoisted_4, _toDisplayString((_a = _ctx.mainData) === null || _a === void 0 ? void 0 : _a.name), 1),
                    _createVNode(_component_base_flex, { class: "product_status_tag_group" }, {
                        default: _withCtx(function () {
                            var _a;
                            return [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.mainData) === null || _a === void 0 ? void 0 : _a.statuses, function (status) {
                                    return (_openBlock(), _createBlock(_component_base_flex, {
                                        key: status === null || status === void 0 ? void 0 : status.text,
                                        class: "flex-row align-items-center product_status_tag"
                                    }, {
                                        default: _withCtx(function () { return [
                                            _createElementVNode("span", _hoisted_5, _toDisplayString(status === null || status === void 0 ? void 0 : status.text), 1)
                                        ]; }),
                                        _: 2
                                    }, 1024));
                                }), 128))
                            ];
                        }),
                        _: 1
                    })
                ];
            }),
            _: 1
        }),
        _createVNode(_component_base_flex, null, {
            default: _withCtx(function () {
                var _a;
                return [
                    _createElementVNode("span", _hoisted_6, _toDisplayString((_a = _ctx.mainData) === null || _a === void 0 ? void 0 : _a.description), 1)
                ];
            }),
            _: 1
        }),
        _createVNode(_component_base_flex, { class: "status_with_icons_group" }, {
            default: _withCtx(function () {
                var _a;
                return [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.mainData) === null || _a === void 0 ? void 0 : _a.statusWithIcons, function (status) {
                        return (_openBlock(), _createBlock(_component_base_flex, {
                            class: "me-5",
                            key: status === null || status === void 0 ? void 0 : status.text
                        }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_base_icon, {
                                    iconName: status === null || status === void 0 ? void 0 : status.icon,
                                    noBorder: ""
                                }, null, 8, ["iconName"]),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(status === null || status === void 0 ? void 0 : status.text), 1)
                            ]; }),
                            _: 2
                        }, 1024));
                    }), 128))
                ];
            }),
            _: 1
        }),
        _createVNode(_component_base_flex, { class: "flex-column" }, {
            default: _withCtx(function () { return [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("Keep in touch")), 1),
                _createVNode(_component_base_flex, null, {
                    default: _withCtx(function () {
                        var _a;
                        return [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.mainData) === null || _a === void 0 ? void 0 : _a.socialShares, function (social) {
                                return (_openBlock(), _createElementBlock("a", {
                                    key: social,
                                    href: social === null || social === void 0 ? void 0 : social.url
                                }, [
                                    _createVNode(_component_BaseIcon, {
                                        iconName: social === null || social === void 0 ? void 0 : social.icon,
                                        isGreenBackground: ""
                                    }, null, 8, ["iconName"])
                                ], 8, _hoisted_9));
                            }), 128))
                        ];
                    }),
                    _: 1
                })
            ]; }),
            _: 1
        })
    ]));
}
