import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
    name: "store",
    setup: function () {
        var store = useStore();
        var storeData = computed(function () {
            return store.getters.getStoreData;
        });
        var mainData = computed(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            return {
                name: (_a = storeData.value) === null || _a === void 0 ? void 0 : _a.store_name,
                description: (_b = storeData.value) === null || _b === void 0 ? void 0 : _b.store_contact_description,
                image_path: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Vegetable_Cart_in_Guntur.jpg/1024px-Vegetable_Cart_in_Guntur.jpg",
                statuses: [
                    {
                        text: (_c = storeData.value) === null || _c === void 0 ? void 0 : _c.store_comment,
                    },
                    {
                        text: "Opens at " + ((_d = storeData.value) === null || _d === void 0 ? void 0 : _d.store_opening_time),
                    },
                ],
                statusWithIcons: [
                    {
                        icon: "bi bi-tags",
                        text: (_e = storeData.value) === null || _e === void 0 ? void 0 : _e.category_name,
                        class: null,
                    },
                    {
                        icon: "bi bi-phone",
                        text: ((_f = storeData.value) === null || _f === void 0 ? void 0 : _f.store_telephone) ? (_g = storeData.value) === null || _g === void 0 ? void 0 : _g.store_telephone : "Not Existed",
                        class: null,
                    },
                    {
                        icon: "bi bi-envelope",
                        text: (_h = storeData.value) === null || _h === void 0 ? void 0 : _h.store_email,
                        class: null,
                    },
                    {
                        icon: "bi bi-compass",
                        text: (_j = storeData.value) === null || _j === void 0 ? void 0 : _j.store_address,
                        class: null,
                    },
                ],
                socialShares: [
                    {
                        icon: "bi bi-facebook",
                        url: (_k = storeData.value) === null || _k === void 0 ? void 0 : _k.store_facebook_link,
                    },
                    {
                        icon: "bi bi-twitter",
                        url: (_l = storeData.value) === null || _l === void 0 ? void 0 : _l.store_twitter_link,
                    },
                    {
                        icon: "bi bi-instagram",
                        url: (_m = storeData.value) === null || _m === void 0 ? void 0 : _m.store_instagram_link,
                    },
                    {
                        icon: "bi bi-youtube",
                        url: (_o = storeData.value) === null || _o === void 0 ? void 0 : _o.store_youtube_link,
                    },
                ],
            };
        });
        return {
            mainData: mainData,
        };
    },
});
